import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DataContext } from '../../../data';
import ProjectDetails from './ProjectDetails';
import { generateURLBasedOnPath } from '../../../helpers/url';
import Button from '../../partials/Buttons/TernaryButton';

const ProjectDetailPage = () => {
    const { id = 0 } = useParams();
    const { allProjects } = useContext(DataContext);
    const project = allProjects.find(project => project.id == id);

    //TODO improve this by adding boolean to project
    const isPersonal = id >= 6;

    return <section id="project-page">
        <div className="container mx-auto flex px-10 py-5 flex-col lg:px-40 min-h-screen">
            <Button {...{ label: '<< Back', href: generateURLBasedOnPath(isPersonal ? 'personalProjects' : `projects`, true), className: 'ml-0' }} />
            <ProjectDetails {...project} /></div>
    </section>
};

export default ProjectDetailPage;