import React from 'react';

const Socials = ({ className }) => {

  const containerClass = `share-buttons ${className}`;

  return <ul className={containerClass} data-source="simplesharingbuttons.com">
    <li><a className="pr-4" href="https://www.linkedin.com/in/seppedijkmans/?originalSubdomain=be" title="Go to my LinkedIn"><i className="fab fa-linkedin fa-2x" aria-hidden="true"></i><span className="sr-only">Go to my LinkedIn</span></a></li>
    <li><a href="mailto:seppe.dijkmans@sditservices.be" title="Send email" ><i className="fas fa-envelope-square fa-2x" aria-hidden="true"></i><span className="sr-only">Send email</span></a></li>
  </ul>;
};

export default Socials;