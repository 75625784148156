import { useState, useEffect } from "react";

export function useLanguage() {
  const [language, setLanguage] = useState(
    localStorage.getItem('language') ||
    ((navigator.language || navigator['userLanguage'])?.includes('nl') || window.location.href.includes('language=nl') ? 'NL' : 'EN')
  );

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const toggleLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return [language, toggleLanguage];
}